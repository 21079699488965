import React from "react";
import PropTypes from "prop-types";

const FlagIconJa = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="flag-ja_svg__b">
          <stop stopColor="#D81441" offset="0%" />
          <stop stopColor="#BB0831" offset="100%" />
        </linearGradient>
        <rect
          id="flag-ja_svg__a"
          x={0}
          y={0}
          width={24}
          height={17.143}
          rx={2}
        />
      </defs>
      <g transform="translate(0 3)" fill="none" fillRule="evenodd">
        <mask id="flag-ja_svg__c" fill="#fff">
          <use xlinkHref="#flag-ja_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#flag-ja_svg__a" />
        <circle
          fill="url(#flag-ja_svg__b)"
          mask="url(#flag-ja_svg__c)"
          cx={12}
          cy={8.571}
          r={5.143}
        />
      </g>
    </svg>
  );
};
FlagIconJa.propTypes = {
  size: PropTypes.number,
};
FlagIconJa.defaultProps = { size: 24 };

export default FlagIconJa;
