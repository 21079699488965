import React from "react";
import PropTypes from "prop-types";
import { IntlContextConsumer, useIntl, Link } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";
import LocaleContext from "../../context/LocaleContext";
import MenuContext from "../../context/MenuContext";

import { Stack } from "../@chipui";
import { Button, IconButton } from "./";
import { IconMenu } from "../../assets/@feathericons";
import { FlagIconEn, FlagIconJa } from "../../assets/@flagicons";
import { logoInCloudColor, logoInCloudWhite } from "../../assets/graphics";

/**
 * NavBar
 */
const NavBar = ({ siteProps, navBarTheme, navBarColor }) => {
  const _linkSize = "text-md4 tracking-tight_xs";
  const _linkColor =
    navBarTheme === "light" ? "text-text3" : "text-altText1_60";
  const _linkStyle = cx(_linkSize, _linkColor, "hover:underline");

  const _activeLinkColor =
    navBarTheme === "light" ? "text-accent2" : "text-altText1";
  const _activeLinkStyle = cx(_linkSize, _activeLinkColor);

  const _logo = navBarTheme === "light" ? logoInCloudColor : logoInCloudWhite;

  const _languageLabelColor =
    navBarTheme === "light" ? theme.colors.accent2 : theme.colors.altText1;

  const _loginLabelColor =
    navBarTheme === "light" ? theme.colors.text3 : theme.colors.altText1_60;

  const intl = useIntl();
  const _menuProductHome = intl.formatMessage({
    id: "ui.menuOptions.product.home",
  });
  const _menuProductFeatures = intl.formatMessage({
    id: "ui.menuOptions.product.features",
  });
  const _menuProductSecurity = intl.formatMessage({
    id: "ui.menuOptions.product.security",
  });
  const _menuProductPricing = intl.formatMessage({
    id: "ui.menuOptions.product.pricing",
  });

  const _menuAccountLogin = intl.formatMessage({
    id: "ui.menuOptions.account.login",
  });
  const _menuAccountRegisterMarketing = intl.formatMessage({
    id: "ui.menuOptions.account.registerMarketing",
  });

  const _flagIconEn = <FlagIconEn />;
  const _flagIconJa = <FlagIconJa />;
  const _iconMenu = <IconMenu />;

  const _languageSwitcher = (
    toLanguageFlag,
    toLanguageStr,
    toggleLocale,
    locale
  ) => {
    return (
      <Button
        mode="button"
        onClick={() => toggleLocale(locale)}
        icon={toLanguageFlag}
        iconPosition="left"
        label={toLanguageStr}
        labelColor={_languageLabelColor}
        frameColor={theme.colors.accent2_25}
      />
    );
  };

  const _navBar = (toLanguageFlag, toLanguageStr, toggleLocale, locale) => {
    return (
      <div
        style={{ backgroundColor: navBarColor }}
        className="flex h-60 md:h-80 lg:h-100 px-10 md:px-20 lg:px-30">
        <div className="flex justify-start items-center h-full">
          <Link to="/">
            <img src={_logo} alt="smartQA" />
          </Link>
        </div>

        <div className="hidden lg:flex flex-1 justify-start items-center h-full ml-30">
          <Stack direction="row" spacing={20}>
            <Link
              className={_linkStyle}
              activeClassName={_activeLinkStyle}
              to="/#home">
              {_menuProductHome}
            </Link>
            <Link
              className={_linkStyle}
              activeClassName={_activeLinkStyle}
              to="/#features">
              {_menuProductFeatures}
            </Link>
            <Link
              className={_linkStyle}
              activeClassName={_activeLinkStyle}
              to="/#pricing">
              {_menuProductPricing}
            </Link>
            {/* <Link
              className={_linkStyle}
              activeClassName={_activeLinkStyle}
              to="/faq">
              FAQ
            </Link> */}
          </Stack>
        </div>

        <div className="flex flex-1 justify-end items-center h-full">
          <div>
            {_languageSwitcher(
              toLanguageFlag,
              toLanguageStr,
              toggleLocale,
              locale
            )}
          </div>

          <div className="w-20 lg:w-30 bg-transparent" />

          <div className="hidden lg:flex justify-end items-center h-full">
            <Button
              mode="href"
              href={siteProps.appLoginUrl}
              label={_menuAccountLogin}
              labelColor={_loginLabelColor}
              frameColor={theme.colors.none}
            />
            <div className="w-10 bg-transparent" />
            <Button
              mode="href"
              href={siteProps.appRegisterUrl}
              label={_menuAccountRegisterMarketing}
              labelColor={theme.colors.altText1}
              frameColor={theme.colors.accent2}
            />
          </div>

          <div className="flex lg:hidden justify-end items-center">
            <MenuContext.Consumer>
              {menuContext => {
                return (
                  <IconButton
                    onClick={() => menuContext.toggleMenu()}
                    icon={_iconMenu}
                    iconColor={theme.colors.bg1}
                    frameColor={theme.colors.accent2}
                  />
                );
              }}
            </MenuContext.Consumer>
          </div>
        </div>
      </div>
    );
  };

  return (
    <IntlContextConsumer>
      {intlContextConsumer => {
        return (
          <LocaleContext.Consumer>
            {localeContext => {
              const detectedLocale = intlContextConsumer.language;
              const toLanguageFlag =
                detectedLocale === "en" ? _flagIconJa : _flagIconEn;
              const toLanguageStr =
                detectedLocale === "en" ? "日本語" : "English";

              return (
                <>
                  {_navBar(
                    toLanguageFlag,
                    toLanguageStr,
                    localeContext.toggleLocale,
                    detectedLocale
                  )}
                </>
              );
            }}
          </LocaleContext.Consumer>
        );
      }}
    </IntlContextConsumer>
  );
};
NavBar.propTypes = {
  navBarTheme: PropTypes.oneOf(["light", "dark"]),
  navBarColor: PropTypes.string,
};
NavBar.defaultProps = {
  navBarTheme: "light",
  navBarColor: "transparent",
};

export default NavBar;
