import React from "react";
import PropTypes from "prop-types";

const IconUsers = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        transform="translate(1 3)"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M16 18v-2a4 4 0 00-4-4H4a4 4 0 00-4 4v2" />
        <circle cx={8} cy={4} r={4} />
        <path d="M22 18v-2a4 4 0 00-3-3.87M15 .13a4 4 0 010 7.75" />
      </g>
    </svg>
  );
};
IconUsers.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconUsers.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconUsers;
