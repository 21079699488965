import React from "react";
import PropTypes from "prop-types";

const IconDatabase = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        transform="translate(3 2)"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <ellipse cx={9} cy={3} rx={9} ry={3} />
        <path d="M18 10c0 1.66-4 3-9 3s-9-1.34-9-3" />
        <path d="M0 3v14c0 1.66 4 3 9 3s9-1.34 9-3V3" />
      </g>
    </svg>
  );
};
IconDatabase.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconDatabase.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconDatabase;
