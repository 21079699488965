import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import * as brandImages from "../assets/brand-icons";

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // Facebook
        { property: `og:url`, content: `https://www.sqa.jp` },
        { property: `og:type`, content: `website` },
        { property: `og:title`, content: `smartQA` },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: `https://www.sqa.jp${brandImages.twitterCard}`,
        },
        { property: `og:image:width`, content: `1200` },
        { property: `og:image:height`, content: `600` },
        // Twitter
        { name: `twitter:card`, content: `summary_large_image` },
        { name: `twitter:title`, content: `smartQA` },
        {
          name: `twitter:description`,
          content: description,
        },
        { name: `twitter:site`, content: `@smartQA` },
        { name: `twitter:creator`, content: `@smartQA` },
        {
          name: `twitter:image:src`,
          content: `https://www.sqa.jp${brandImages.facebookCard}`,
        },
        // Microsoft
        { name: `msapplication-TileColor`, content: `#20A8D8` },
        {
          name: `msapplication-TileImage`,
          content: `${brandImages.msIcon144}`,
        },
      ].concat(meta)}
      link={[
        // Favicon
        {
          rel: `shortcut icon`,
          type: `image/x-icon`,
          href: `${brandImages.favicon}`,
        },
        // Apple
        {
          rel: `apple-touch-icon`,
          sizes: `57x57`,
          type: `image/png`,
          href: `${brandImages.appleIcon57}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `60x60`,
          type: `image/png`,
          href: `${brandImages.appleIcon60}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `72x72`,
          type: `image/png`,
          href: `${brandImages.appleIcon72}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `76x76`,
          type: `image/png`,
          href: `${brandImages.appleIcon76}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `114x114`,
          type: `image/png`,
          href: `${brandImages.appleIcon114}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `120x120`,
          type: `image/png`,
          href: `${brandImages.appleIcon120}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `144x144`,
          type: `image/png`,
          href: `${brandImages.appleIcon144}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `152x152`,
          type: `image/png`,
          href: `${brandImages.appleIcon152}`,
        },
        {
          rel: `apple-touch-icon`,
          sizes: `180x180`,
          type: `image/png`,
          href: `${brandImages.apple_icon_180}`,
        },
        // Android
        {
          rel: `icon`,
          sizes: `128x128`,
          type: `image/png`,
          href: `${brandImages.androidIcon128}`,
        },
        {
          rel: `icon`,
          sizes: `192x192`,
          type: `image/png`,
          href: `${brandImages.androidIcon192}`,
        },
      ]}
    />
  );
};
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};
SEO.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
};

export default SEO;
