import React from "react";
import PropTypes from "prop-types";

const IconCloud = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path
        d="M18 10h-1.26A8 8 0 109 20h9a5 5 0 000-10h0z"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IconCloud.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconCloud.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconCloud;
