import React from "react";
import PropTypes from "prop-types";

/**
 * Stack
 */
const Stack = ({ direction, spacing, children }) => {
  const count = React.Children.count(children);
  const marginKey = direction === "column" ? "marginBottom" : "marginRight";
  const directionStyle = direction === "column" ? "flex-col" : "flex-row";

  return (
    <div className={`flex ${directionStyle}`}>
      {React.Children.map(children, (child, index) => {
        if (index === count - 1) {
          return child;
        } else {
          return React.cloneElement(child, {
            style: { [marginKey]: spacing },
          });
        }
      })}
    </div>
  );
};
Stack.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  spacing: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
Stack.defaultProps = {
  direction: "column",
  spacing: 10,
};
export default Stack;
