import React from "react";
import PropTypes from "prop-types";

const FlagIconEn = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="flag-en_svg__e">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#F0F0F0" offset="100%" />
        </linearGradient>
        <filter
          x="-6.3%"
          y="-8.8%"
          width="112.5%"
          height="135%"
          filterUnits="objectBoundingBox"
          id="flag-en_svg__c">
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            in="shadowOffsetOuter1"
          />
        </filter>
        <rect
          id="flag-en_svg__a"
          x={0}
          y={0}
          width={24}
          height={17.143}
          rx={2}
        />
        <path
          d="M8.571 5.714a.571.571 0 110 1.143.571.571 0 010-1.143zm-2.285 0a.571.571 0 110 1.143.571.571 0 010-1.143zM4 5.714a.571.571 0 110 1.143.571.571 0 010-1.143zm-2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zm1.143-1.143a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zM8.57 3.43a.571.571 0 110 1.142.571.571 0 010-1.142zm-2.285 0a.571.571 0 110 1.142.571.571 0 010-1.142zM4 3.429A.571.571 0 114 4.57.571.571 0 014 3.43zm-2.286 0a.571.571 0 110 1.142.571.571 0 010-1.142zm1.143-1.143a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zM1.714 1.143a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zm2.286 0a.571.571 0 110 1.143.571.571 0 010-1.143zm2.285 0a.571.571 0 110 1.143.571.571 0 010-1.143z"
          id="flag-en_svg__d"
        />
      </defs>
      <g transform="translate(0 3)" fill="none" fillRule="evenodd">
        <mask id="flag-en_svg__b" fill="#fff">
          <use xlinkHref="#flag-en_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#flag-en_svg__a" />
        <path
          d="M24 16v1.143H0V16h24zm0-2.286v1.143H0v-1.143h24zm0-2.285v1.142H0V11.43h24zm0-2.286v1.143H0V9.143h24zm0-2.286V8H0V6.857h24zm0-2.286v1.143H0V4.571h24zm0-2.285v1.143H0V2.286h24zM24 0v1.143H0V0h24z"
          fill="#D02F44"
          mask="url(#flag-en_svg__b)"
        />
        <path fill="#46467F" mask="url(#flag-en_svg__b)" d="M0 0h10.286v8H0z" />
        <g mask="url(#flag-en_svg__b)">
          <use
            fill="#000"
            filter="url(#flag-en_svg__c)"
            xlinkHref="#flag-en_svg__d"
          />
          <use fill="url(#flag-en_svg__e)" xlinkHref="#flag-en_svg__d" />
        </g>
      </g>
    </svg>
  );
};
FlagIconEn.propTypes = {
  size: PropTypes.number,
};
FlagIconEn.defaultProps = { size: 24 };

export default FlagIconEn;
