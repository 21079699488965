import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useIntl, Link } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "./";
import {
  logoInCloudColor,
  iconSocialGitHub,
  iconSocialTwitter,
} from "../../assets/graphics";

/**
 * Footer
 */
const Footer = ({ siteProps }) => {
  const _linkStyle = cx(
    "text-md4 text-text3 tracking-tight_xs h-40",
    "hover:underline"
  );
  const _activeLinkStyle = cx("text-md4 text-accent2 tracking-tight_xs");

  const intl = useIntl();
  const _menuProductTitle = intl.formatMessage({
    id: "ui.menuOptions.product.sectionTitle",
  });
  const _menuProductHome = intl.formatMessage({
    id: "ui.menuOptions.product.home",
  });
  const _menuProductFeatures = intl.formatMessage({
    id: "ui.menuOptions.product.features",
  });
  const _menuProductSecurity = intl.formatMessage({
    id: "ui.menuOptions.product.security",
  });
  const _menuProductPricing = intl.formatMessage({
    id: "ui.menuOptions.product.pricing",
  });

  const _menuInfoTitle = intl.formatMessage({
    id: "ui.menuOptions.info.sectionTitle",
  });
  const _menuInfoAbout = intl.formatMessage({
    id: "ui.menuOptions.info.about",
  });
  const _menuInfoContact = intl.formatMessage({
    id: "ui.menuOptions.info.contact",
  });

  const _menuLegalTitle = intl.formatMessage({
    id: "ui.menuOptions.legal.sectionTitle",
  });
  const _menuLegalTerms = intl.formatMessage({
    id: "ui.menuOptions.legal.terms",
  });
  const _menuLegalPrivacy = intl.formatMessage({
    id: "ui.menuOptions.legal.privacy",
  });

  const _menuSocialTitle = intl.formatMessage({
    id: "ui.menuOptions.social.sectionTitle",
  });
  const _menuSocialGitHub = intl.formatMessage({
    id: "ui.menuOptions.social.github",
  });
  const _menuSocialTwitter = intl.formatMessage({
    id: "ui.menuOptions.social.twitter",
  });

  const _companyDescription = intl.formatMessage({
    id: "content.shared.footer.description",
  });
  const _companyOurWebsite = intl.formatMessage({
    id: "content.shared.footer.ourWebsite",
  });
  const _companyName = intl.formatMessage({
    id: "content.shared.footer.companyName",
  });

  const menuSection = (sectionTitle, arr) => {
    return (
      <div className="flex flex-col">
        <h3 className="font-medium text-accent2_80 text-sm2 uppercase tracking-wide_md h-40">
          {sectionTitle}
        </h3>
        <nav className="flex flex-col">
          {arr.map((menuItem, index) => {
            return (
              <Link
                key={index}
                className={_linkStyle}
                activeClassName={_activeLinkStyle}
                to={menuItem.to}>
                {menuItem.label}
              </Link>
            );
          })}
        </nav>
      </div>
    );
  };

  const aboutLink =
    intl.locale === "en"
      ? "https://incloud.jp/about-us/our-company/"
      : "https://incloud.jp/ja/%e4%bc%81%e6%a5%ad%e6%83%85%e5%a0%b1/%e4%bc%81%e6%a5%ad%e6%83%85%e5%a0%b1/";

  const contactLink =
    intl.locale === "en"
      ? "https://incloud.jp/contact-us"
      : "https://incloud.jp/ja/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/";

  return (
    <footer className="bg-bg1">
      <Wrapper>
        <div className="flex flex-col md:flex-row">
          <div className="w-auto md:w-3/12 py-20 md:py-40">
            <div className="flex flex-col justify-start items-start">
              <Link to="/">
                <img src={logoInCloudColor} alt="smartQA" />
              </Link>

              <div className="bg-transparent h-20" />

              <p className="text-text1 text-md4 max-w-220">
                {_companyDescription}{" "}
                <span className="text-accent1 text-md4">
                  <a href={siteProps.parentCompanyUrl}>{_companyOurWebsite}</a>
                </span>
              </p>
            </div>
          </div>

          <div className="w-auto md:w-2/12 py-20 md:py-40">
            {menuSection(_menuProductTitle, [
              { to: "/#home", label: _menuProductHome },
              { to: "/#features", label: _menuProductFeatures },
              { to: "/#pricing", label: _menuProductPricing },
            ])}
          </div>

          <div className="w-auto md:w-2/12 py-20 md:py-40">
            {menuSection(
              _menuInfoTitle,
              []
              // [
              //   { to: "/about", label: _menuInfoAbout },
              //   { to: "/contact", label: _menuInfoContact },
              // ]
            )}
            <nav className="flex flex-col">
              <a
                target="_blank"
                className="text-md4 text-text3 tracking-tight_xs h-40 hover:underline"
                href={aboutLink}>
                {_menuInfoAbout}
              </a>
              <a
                target="_blank"
                className="text-md4 text-text3 tracking-tight_xs h-40 hover:underline"
                href={contactLink}>
                {_menuInfoContact}
              </a>
            </nav>
          </div>

          <div className="w-auto md:w-3/12 py-20 md:py-40">
            {menuSection(_menuLegalTitle, [
              { to: "/terms-and-conditions", label: _menuLegalTerms },
              { to: "/privacy-policy", label: _menuLegalPrivacy },
            ])}
          </div>
        </div>

        <div className="wrapper flex py-40">
          <p className="text-text3 text-sm">
            Copyright &copy; {new Date().getFullYear()}
            {/* {_companyName} */}
            {intl.locale === "en" ? " Incloud Co. Ltd" : " InCloud株式会社"}
          </p>
        </div>
      </Wrapper>
    </footer>
  );
};

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
