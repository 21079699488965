import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

/**
 * SectionHeading
 */
const SectionHeading = ({
  mainText,
  subText,
  size,
  textColor,
  subTextColor,
  lineColor,
  alignment,
  isHideSeparator,
}) => {
  const _alignment = {
    left: "items-start",
    center: "items-center",
  };
  const _headingSize = {
    sm: "text-md2 lg:text-md",
    md: "text-md md:text-lg4 lg:text-lg3",
  };
  const _headingLetterSpacing = {
    sm: "tracking-tight_sm",
    md: "tracking-tight_md lg:tracking-tight_lg",
  };
  const _subTextSize = {
    sm: "text-sm",
    md: "text-sm",
  };
  const _lineWidth = {
    sm: "w-60",
    md: "w-100",
  };
  const _headingAndLineSpacing = {
    sm: "mb-16",
    md: "mb-24",
  };
  const _lineVisibility = isHideSeparator ? cx("hidden") : cx("block");

  return (
    <div className={cx("flex flex-col justify-center", _alignment[alignment])}>
      <p
        style={{
          color: subTextColor,
        }}
        className={cx(
          "hidden",
          "font-medium tracking-wide_sm uppercase mb-8",
          _subTextSize[size]
        )}>
        {subText}
      </p>
      <h2
        style={{
          color: textColor,
        }}
        className={cx(
          "font-medium leading-tight",
          _headingSize[size],
          _headingLetterSpacing[size],
          _headingAndLineSpacing[size]
        )}>
        {mainText}
      </h2>
      <div
        style={{
          backgroundColor: lineColor,
        }}
        className={cx("h-4", _lineWidth[size], _lineVisibility)}></div>
    </div>
  );
};
SectionHeading.propTypes = {
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md"]),
  alignment: PropTypes.oneOf(["left", "center"]),
  textColor: PropTypes.string,
  subTextColor: PropTypes.string,
  lineColor: PropTypes.string,
  isHideSeparator: PropTypes.bool,
};
SectionHeading.defaultProps = {
  size: "md",
  alignment: "center",
  textColor: "black",
  subTextColor: "black",
  lineColor: "black",
  isHideSeparator: false,
};

export default SectionHeading;
