import React from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";
import MenuContext from "../../context/MenuContext";

import { Button, IconButton } from "./";
import { IconCross } from "../../assets/@feathericons";
import { logoInCloudWhite } from "../../assets/graphics";

/**
 * MobileNav
 */
const MobileNav = ({ siteProps }) => {
  const _linkStyle = cx(
    "text-md3 text-altText1_60 tracking-tight_xs text-left",
    "px-20 py-16",
    "bg-transparent",
    "hover:bg-accent2_25"
  );

  const intl = useIntl();
  const _menuProductTitle = intl.formatMessage({
    id: "ui.menuOptions.product.sectionTitle",
  });
  const _menuProductHome = intl.formatMessage({
    id: "ui.menuOptions.product.home",
  });
  const _menuProductFeatures = intl.formatMessage({
    id: "ui.menuOptions.product.features",
  });
  const _menuProductSecurity = intl.formatMessage({
    id: "ui.menuOptions.product.security",
  });
  const _menuProductPricing = intl.formatMessage({
    id: "ui.menuOptions.product.pricing",
  });

  const _menuInfoTitle = intl.formatMessage({
    id: "ui.menuOptions.info.sectionTitle",
  });
  const _menuInfoAbout = intl.formatMessage({
    id: "ui.menuOptions.info.about",
  });
  const _menuInfoContact = intl.formatMessage({
    id: "ui.menuOptions.info.contact",
  });

  const _menuLegalTitle = intl.formatMessage({
    id: "ui.menuOptions.legal.sectionTitle",
  });
  const _menuLegalTerms = intl.formatMessage({
    id: "ui.menuOptions.legal.terms",
  });
  const _menuLegalPrivacy = intl.formatMessage({
    id: "ui.menuOptions.legal.privacy",
  });

  const _registerEmailInputText = intl.formatMessage({
    id: "content.shared.forms.register.emailInputText",
  });
  const _registerButtonLabel = intl.formatMessage({
    id: "content.shared.forms.register.buttonLabel",
  });

  const _menuAccountLogin = intl.formatMessage({
    id: "ui.menuOptions.account.login",
  });
  const _menuAccountRegisterMarketing = intl.formatMessage({
    id: "ui.menuOptions.account.registerMarketing",
  });

  const _iconClose = <IconCross />;

  const _navigateTo = (route) => {
    navigate(route);
  };

  const _mobileNavBar = () => {
    return (
      <div className="flex h-60">
        <div className="flex justify-start items-center w-100 p-10">
          <MenuContext.Consumer>
            {menuContext => {
              return (
                <IconButton
                  onClick={() => menuContext.toggleMenu()}
                  icon={_iconClose}
                  iconColor={theme.colors.bg1}
                  frameColor={theme.colors.accent2}
                />
              );
            }}
          </MenuContext.Consumer>
        </div>

        <div className="flex flex-1 justify-center items-center h-full p-10">
          <img src={logoInCloudWhite} alt="smartQA" />
        </div>

        <div className="flex justify-end items-center w-100 p-10">
          <Button
            mode="href"
            href={siteProps.appLoginUrl}
            label={_menuAccountLogin}
            labelColor={theme.colors.altText1}
            frameColor={theme.colors.bg1_25}
            size="sm"
          />
        </div>
      </div>
    );
  };

  const _navigateToFeature = (menuContext) => {
    menuContext.toggleMenu()
    navigate(`/${intl.locale}/#features`)
  }

  const _navigateToPricing = (menuContext) => {
    menuContext.toggleMenu()
    navigate(`/${intl.locale}/#pricing`)
  }

  const _productNav = menuContext => {
    return (
      <div>
        <h3 className="font-medium text-accent2_80 text-sm2 uppercase tracking-wide_md px-20 py-8">
          {_menuProductTitle}
        </h3>
        <nav className="flex flex-col">
          <button 
            onClick={() => _navigateTo("/#home")} 
            className={_linkStyle}>
            {_menuProductHome}
          </button>
          <button
            onClick={() => _navigateToFeature(menuContext)}
            className={_linkStyle}>
            {_menuProductFeatures}
          </button>
          <button
            onClick={() => _navigateToPricing(menuContext)}
            className={_linkStyle}>
            {_menuProductPricing}
          </button>
          {/* <button onClick={() => _navigateTo("/faq")} className={_linkStyle}>
            FAQ
          </button> */}
        </nav>
      </div>
    );
  };

  const _infoNav = menuContext => {
    return (
      <div className="flex flex-col">
        <h3 className="font-medium text-accent2_80 text-sm2 uppercase tracking-wide_md px-20 py-8">
          {_menuInfoTitle}
        </h3>
        <nav className="flex flex-col">
          <button onClick={() => _navigateTo("/about")} className={_linkStyle}>
            {_menuInfoAbout}
          </button>
          <button
            onClick={() => _navigateTo("/contact")}
            className={_linkStyle}>
            {_menuInfoContact}
          </button>
        </nav>
      </div>
    );
  };

  const _legalNav = menuContext => {
    return (
      <div className="flex flex-col">
        <h3 className="font-medium text-accent2_80 text-sm2 uppercase tracking-wide_md px-20 py-8">
          {_menuLegalTitle}
        </h3>
        <nav className="flex flex-col">
          <button
            onClick={() => _navigateTo("/terms-and-conditions")}
            className={_linkStyle}>
            {_menuLegalTerms}
          </button>
          <button
            onClick={() => _navigateTo("/privacy-policy")}
            className={_linkStyle}>
            {_menuLegalPrivacy}
          </button>
        </nav>
      </div>
    );
  };

  const _registerForm = () => {
    return (
      <div className="flex flex-col bg-accent1_15 p-16 rounded">
        <input
          className="text-md4 rounded w-full h-56 px-10 mb-8 outline-none"
          type="text"
          name="email"
          placeholder={_registerEmailInputText}
        />
        <Button
          mode="href"
          href={siteProps.appRegisterUrl}
          label={_registerButtonLabel}
          roundness="round"
          labelColor={theme.colors.altText1}
          frameColor={theme.colors.accent2}
          size="lg"
        />
      </div>
    );
  };

  return (
    <MenuContext.Consumer>
      {menuContext => {
        console.log(menuContext.isMenuOpen);
        const menuBaseStyle =
          "fixed lg:hidden flex-col justify-start w-full h-screen bg-colorBg1";
        const menuStyle = menuContext.isMenuOpen
          ? cx(menuBaseStyle, "flex")
          : cx(menuBaseStyle, "hidden");

        return (
          <div className={menuStyle} style={{zIndex: 1000}}>
            {_mobileNavBar()}
            <div className="bg-transparent h-20"></div>
            {_productNav(menuContext)}
            <div className="bg-transparent h-20"></div>
            {_registerForm()}
            <div className="bg-transparent h-20"></div>
            {_infoNav(menuContext)}
            <div className="bg-transparent h-20"></div>
            {_legalNav(menuContext)}
          </div>
        );
      }}
    </MenuContext.Consumer>
  );
};
MobileNav.propTypes = {};
MobileNav.defaultProps = {};

export default MobileNav;
