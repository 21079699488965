import React from "react";
import PropTypes from "prop-types";

const IconLock = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        transform="translate(3 2)"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <rect y={9} width={18} height={11} rx={2} />
        <path d="M4 9V5a5 5 0 1110 0v4" />
      </g>
    </svg>
  );
};
IconLock.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconLock.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconLock;
