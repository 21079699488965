import React from "react";
import PropTypes from "prop-types";

const IconMenu = ({ color, size, strokeWidth }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M3 12h18M3 6h18M3 18h18" />
    </svg>
  );
};
IconMenu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.string,
};
IconMenu.defaultProps = { color: "black", size: 24, strokeWidth: "2" };

export default IconMenu;
