import React from "react";
import PropTypes from "prop-types";

const IconUnlock = ({ color, size, strokeWidth }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round">
      <rect x={3} y={11} width={18} height={11} rx={2} ry={2} />
      <path d="M7 11V7a5 5 0 019.9-1" />
    </svg>
  );
};
IconUnlock.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconUnlock.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconUnlock;
