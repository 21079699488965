import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * Wrapper
 */
const Wrapper = ({ children, hasSidePadding, showDebugBg }) => {
  const _sidePadding = hasSidePadding ? "px-20 md:px-30 lg:px-40" : null;
  const _debugBg = showDebugBg ? "bg-red-200" : null;

  return (
    <div className={cx("wrapper", _sidePadding, _debugBg)}>{children}</div>
  );
};
Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hasSidePadding: PropTypes.bool,
  showDebugBg: PropTypes.bool,
};
Wrapper.defaultProps = { hasSidePadding: true, showDebugBg: false };

export default Wrapper;
