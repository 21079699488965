import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "./styles/reset.css";
import "./styles/global.css";
import "./styles/tailwind.css";
import "./styles/docs.css";
import "./styles/slider.css";
import "./styles/slick-theme.css";

import { NavBar, Footer, MobileNav } from "./core";

/**
 * Layout
 */
const Layout = ({ children, navBarTheme, navBarColor }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            parentCompanyUrl
            appLoginUrl
            appRegisterUrl
            companyMailToUrl
            companyGitHubUrl
            companyTwitterUrl
          }
        }
      }
    `
  );

  const {
    parentCompanyUrl,
    appLoginUrl,
    appRegisterUrl,
    companyMailToUrl,
    companyGitHubUrl,
    companyTwitterUrl,
  } = data.site.siteMetadata;

  return (
    <div className="bg-colorBg2">
      <MobileNav siteProps={{ appLoginUrl, appRegisterUrl }} />
      <NavBar
        siteProps={{ appLoginUrl, appRegisterUrl }}
        navBarTheme={navBarTheme}
        navBarColor={navBarColor}
      />
      <main>{children}</main>
      <Footer
        siteProps={{ parentCompanyUrl, companyGitHubUrl, companyTwitterUrl }}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  navBarTheme: PropTypes.oneOf(["light", "dark"]),
  navBarColor: PropTypes.string,
};
Layout.defaultProps = {
  navBarTheme: "light",
  navBarColor: "transparent",
};

export default Layout;
