import logoInCloudColor from "./logos/logo-incloud-color.svg";
import logoInCloudWhite from "./logos/logo-incloud-white.svg";
import iconSocialGitHub from "./social/icon-social-github.svg";
import iconSocialTwitter from "./social/icon-social-twitter.svg";
import iconSocialLinkedIn from "./social/icon-social-linkedin.svg";
import topDiagonalBg from "./backgrounds/top-diagonal-bg.svg";
import bottomDiagonalBg_colorBg2 from "./backgrounds/bottom-diagonal-bg-colorBg2.svg";
import bottomDiagonalBg_bg1 from "./backgrounds/bottom-diagonal-bg-bg1.svg";
import bottomDiagonalBg_bg2 from "./backgrounds/bottom-diagonal-bg-bg2.svg";

export {
  logoInCloudColor,
  logoInCloudWhite,
  iconSocialGitHub,
  iconSocialTwitter,
  iconSocialLinkedIn,
  topDiagonalBg,
  bottomDiagonalBg_colorBg2,
  bottomDiagonalBg_bg1,
  bottomDiagonalBg_bg2,
};
