import React from "react";
import PropTypes from "prop-types";

const IconPlus = ({ color, size, strokeWidth }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M12 5v14M5 12h14" />
    </svg>
  );
};
IconPlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconPlus.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconPlus;
